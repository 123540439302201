import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Typography, Container, Paper, Avatar, IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import smalllogo from './urbanEyeLogo.png';
import { loginUser, registerUser, useUserDispatch } from '../../context/UserContext';
import { Toast } from 'primereact/toast';


const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#f5f5f7',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(8),
    borderRadius: theme.spacing(3),
    textAlign: 'center',
    width: '600px', // Fixed width
  },
  logo: {
    // width: theme.spacing(20),
    // height: theme.spacing(20),
    width: 225,
    height: 75,
    marginBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold', // Make text bold
    fontSize: '1.5rem', // Increase font size
    color: '#2c7873', // Set custom color
  },
  form: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },
}));

const NewLogin = (props) => {
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordText, setshowPasswordText] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  var [error, setError] = useState(null);
  var [isLoading, setIsLoading] = useState(false);
  var [message, setMessage] = useState(null);

  var userDispatch = useUserDispatch();
  const toast = useRef(null); // Use ref for Toast

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    setShowPassword(true);

    // if (isLogin) {
    //   // Proceed to password entry
    //   setShowPassword(true);
    // } else {
    //   // Toggle to registration view
    //   setIsLogin(false);
    // }
  };

  // const handlePasswordSubmit = (e) => {
  //   e.preventDefault();
  //   // Handle login or registration submission
  //   console.log({ email, password });
  // };


  const handlePasswordSubmit = async (e) => {
    //e.preventDefault();
    if (isLogin) {
      try {
        // const response = await axios.post(LOGIN_API_URL, { email, password });
        // console.log('Login successful:', response.data);
  
        // Call the loginUser function from context
        loginUser(userDispatch, email, password, props.history, setIsLoading, setError, setMessage); // Assuming loginUser takes email, password, and history
      } catch (error) {
        console.error('Login failed:', error);
        // Handle login error, show error message to the user
      }
    } else {
      try {
        // const response = await axios.post(REGISTER_API_URL, { email, password });
        // console.log('Registration successful:', response.data);
  
        // After successful registration, automatically log in the user
        // This can be done by calling the loginUser function from context
        registerUser(userDispatch, email, password, props.history, setIsLoading, setError, setMessage); // Assuming loginUser takes email, password, and history
      } catch (error) {
        console.error('Registration failed:', error);
        // Handle registration error, show error message to the user
      }
    }
  };

  useEffect(()=>{
    
    if(message){
      console.log(message, 'mess')
      Object.keys(message).forEach(field => {
        toast.current.show({
            severity: (field === 'success') ? 'success' : 'error',
            summary: (field === 'success') ? 'Success' :  'Error',
            detail: message[field],
            life: 3000
        });
      });
      setIsLogin(true)
      setEmail('')
      setPassword('')
    } 
    console.log(message, 'errr')
  },[message])


  return (
    <div className={classes.container}>
      <Paper className={classes.paper} elevation={3}>
        <img src={smalllogo} alt="logo" className={classes.logo} />
        <Typography variant="h4" className={classes.title}>
          {isLogin ? 'Login' : 'Create your Account'}
        </Typography>
        <form className={classes.form} onSubmit={handleEmailSubmit}>
          <TextField
            variant="outlined"
            required
            fullWidth
            label="Email Address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {showPassword && (
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Password"
              type={showPasswordText ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setshowPasswordText(!showPasswordText)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            onClick={() => handlePasswordSubmit()} 
            color="primary"
          >
            {isLogin ? 'Next' : 'Create Account'}
          </Button>
        </form>
        <Typography variant="body2" style={{ marginTop: '1rem' }}>
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <Button onClick={() => setIsLogin(!isLogin)} color="primary">
            {isLogin ? 'Create an account' : 'Sign in'}
          </Button>
        </Typography>
        <Toast ref={toast} /> 
      </Paper>
    </div>
  );
};

export default NewLogin;
