import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import axios from 'axios';
import './index.css';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
// import { trigger } from "./utils/events";
import { Provider } from 'react-redux';

import App from './App';
import { config } from './config';
import { LayoutProvider } from './context/LayoutContext';
import { UserProvider } from './context/UserContext';
import { Store } from './redux/Store';
import * as serviceWorker from './serviceWorker';
import Themes from './themes';

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('id_token');
if (token) {
  axios.defaults.headers.common.Authorization = `JWT ${  token}`;
}

if (config.isWebView === 'true') {
  window.ReactNativeWebView = {
    // eslint-disable-next-line no-console
    postMessage: (data) => console.log('ReactNativeWebView', data)
  };
}
// console.log("config.isWebView=='true'", config.isWebView=="true")
// console.log('config.isWebView', config.isWebView, typeof(config.isWebView), window.ReactNativeWebView)
ReactDOM.render(
  <Provider store={Store}>
    <LayoutProvider>
      <UserProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} autoHideDuration={3000}>
              <App />
          </SnackbarProvider>
        </ThemeProvider>
      </UserProvider>
    </LayoutProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();