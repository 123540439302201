import { Drawer, IconButton, List, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
// import FormControl from '@material-ui/core/FormControl';
// import { Select, MenuItem } from "@material-ui/core";
// import InputLabel from '@material-ui/core/InputLabel';
import { useHistory } from 'react-router-dom';

import {
  toggleSidebar,
  useLayoutDispatch,
  useLayoutState,
} from '../../context/LayoutContext';

import SidebarLink from './components/SidebarLink/SidebarLink';
import useStyles from './styles';

const structure = [
  { id: 0, type: 'divider' },
  { id: 1, label: 'Geospatial Dashboard', link: '/app/workDashboard', icon: <Icons.Map /> },
  // { id: 2, label: 'Work Cycle Summary', link: '/app/workCyclesSummary', icon: <Icons.AccessAlarm /> },
  // {
  //   id: 4,
  //   label: 'Devices Summary',
  //   link: '/app/devicesSummary',
  //   icon: <Icons.Assignment />,
  // },
  {
    id: 3,
    label: 'Device Management',
    link: '/app/deviceManagement/manageDevices',
    icon: <Icons.Settings />,
  },
  {
    id: 6,
    label: <> Worksite / Polygon <br /> Management</> ,
    link: '/app/locationManagement',
    icon: <Icons.Map />,
  },
  { id: 5, type: 'divider' },
];

var Sidebar = ({ location }) => {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var layoutState = useLayoutState();
  let selectedOrg = null;
  let selectedOrgName = null;
  let showSidebarItem = false;
  var [isPermanent, setPermanent] = useState(true);
  let isEnterpriseUser = localStorage.getItem('isEnterpriseUser');
  let enterpriseOrgs = JSON.parse(localStorage.getItem('enterpriseOrgs'));
  selectedOrg = enterpriseOrgs && enterpriseOrgs.selectedOrg;
  selectedOrgName = enterpriseOrgs && enterpriseOrgs.selectedOrgName;
  // let showWorkCycleDashboard = JSON.parse(localStorage.getItem('showWorkCycleDashboard'))
  if(selectedOrg !== null && isEnterpriseUser === 'true') {
    showSidebarItem = true;
  } else if(isEnterpriseUser === 'false') {
    showSidebarItem = true;
  }
  // if(showWorkCycleDashboard && showWorkCycleDashboard.showWorkCycleDashboard=== false){
  //   structure && structure.map(s=>{
  //     if(s.label=== 'Work Cycle Summary' ){
  //       s.label = 'Locations Dashboard'
  //       s.link = 'app/dashboard/'+ showWorkCycleDashboard.workCycleKey
  //       s.icon = <Icons.Map />
  //     }
  //   })
  // } else{
  //   structure && structure.map(s=>{
  //     if(s.label=== 'Locations Dashboard'){
  //       s.label = "Work Cycle Summary" 
  //       s.link = "/app/workCyclesSummary" 
  //       s.icon = <Icons.AccessAlarm /> 
  //     }
  //   })
  // }

  useEffect(function() {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const saveSelectedOrg = () =>{
    let enterpriseOrg = localStorage.getItem('enterpriseOrganization');
    localStorage.setItem('organization',enterpriseOrg);
    history.push({
      pathname: '/app/redirectPage',
      state: {fromSidebar: true }
    });
  };

  const toggleSidebarAction =  () => {
    toggleSidebar(layoutDispatch);
  };

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.mobileBackButton}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}>
          <Icons.Close
            classes={{
              root: classNames(
                classes.headerIcon,
                classes.headerIconCollapse,
              ),
            }} style={{ color: '#2C7873' }}
          />
        </IconButton>

      </div>
      {isSidebarOpened === true ?
        <div style={{ marginTop: '5%' }}>
          <img style={{ marginLeft: '15%' }} src="icon.png" alt="" width="41" height="40" />
          <Typography style={{ marginTop: '5%', float: 'right', marginRight: '25%', fontFamily: '\'Karla\', sans-serif', fontWeight: 'bold' }} >Hi, {localStorage.getItem('first_name')}</Typography>
        </div> :
        null}

      {isSidebarOpened === true && selectedOrg !== null && selectedOrgName !== null && isEnterpriseUser === 'true' ? 
        <div style={{ padding: 20 }}>
          <div style={{display: 'flex'}}>
            <div style={{fontWeight: 'bold', marginRight: '2%'}}>  Default Organization :  </div>
          </div>
          <div style={{display: 'flex', marginTop: '5%'}}>
            <div style={{fontWeight: 'normal', marginRight: '3%'}}>  {selectedOrgName}  </div>
            <div style={{marginTop: '2px', cursor: 'pointer'}} onClick={()=> saveSelectedOrg()} ><Icons.Edit style={{fontSize: 17}} />  </div>
          </div>
          {/* 
          <div>
                   <FormControl style={{ width: '100%' }} className={classes.formControl}>
                        <InputLabel id="org-key-label">Organization</InputLabel>
                        <Select
                            labelId="org-key-label"
                            id="org-key"
                            value={localStorage.getItem('organization')}
                            onChange={(e) => saveSelectedOrg(e.target.value)}
                        >
                            <MenuItem value="" disabled>Select an Organization</MenuItem>
                            {enterpriseOrgs && enterpriseOrgs.enterpriseOrgs.map(l=>{
                                return (
                                    <MenuItem value={l.key}>{l.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl> 
              </div>  */}
        </div> :  
        null}
      {showSidebarItem === true && <List className={classes.sidebarList}>
        {structure.map((link, i) => (
          <SidebarLink
            key={i}
            location={location}
            isSidebarOpened={isSidebarOpened}
            toggleSidebarAction={toggleSidebarAction}
            {...link}
          />
        ))}
      </List>}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
};

export default withRouter(Sidebar);
