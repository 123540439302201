import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { bbox, centroid } from '@turf/turf';
import axios from 'axios';
import moment from 'moment';

import { APIStatus } from '../APIStatus';
import { revertAll } from '../globalSlice';

const initialState = {
  serviceS3FoldersStatus: APIStatus.idle,
  serviceS3Folders: null,
  serviceS3FoldersError: null,
  frequencyS3FoldersStatus: APIStatus.idle,
  frequencyS3Folders: null,
  frequencyS3FoldersError: null,
  dataS3FoldersStatus: APIStatus.idle,
  dataS3Folders: null,
  dataS3FoldersError: null,
  serviceLocationsStatus: APIStatus.idle,
  serviceLocations: null,
  serviceLocationsError: null,
  serviceSummaryStatus : APIStatus.idle,
  serviceSummary : null, 
  serviceSummaryError : null,
  propertyPolygonsStatus : APIStatus.idle,
  propertyPolygons : null, 
  propertyPolygonsError : null,
  propertyListStatus : APIStatus.idle,
  propertyList : null, 
  propertyListError : null,
  turfSummaryStatus : APIStatus.idle,
  turfSummary : null,
  turfSummaryError: null,
  softEdgeSummaryStatus : APIStatus.idle,
  softEdgeSummary : null,
  softEdgeSummaryError: null,
  hardEdgeSummaryStatus : APIStatus.idle,
  hardEdgeSummary : null,
  hardEdgeSummaryError: null,
  propertySummaryStatus : APIStatus.idle,
  propertySummary : null, 
  propertySummaryError : null,
};

const apiConfig = {
  getdataS3Folders: {
    pending: 'dataS3FoldersStatus',
    fulfilled: 'dataS3Folders',
    rejected: 'dataS3FoldersError'
  },
  getserviceSummary: {
    pending: 'serviceSummaryStatus',
    fulfilled: 'serviceSummary',
    rejected: 'serviceSummaryError'
  },
  getpropertyPolygon: {
    pending: 'propertyPolygonsStatus',
    fulfilled: 'propertyPolygons',
    rejected: 'propertyPolygonsError'
  },
  getPropertyList: {
    pending: 'propertyListStatus',
    fulfilled: 'propertyList',
    rejected: 'propertyListError'
  },
  getPropertySummary: {
    pending: 'propertySummaryStatus',
    fulfilled: 'propertySummary',
    rejected: 'propertySummaryError'
  },
  getTurfSummary :{
    pending: 'turfSummaryStatus',
    fulfilled: 'turfSummary',
    rejected: 'turfSummaryError'
  },
  getHardEdgeSummary :{
    pending: 'hardEdgeSummaryStatus',
    fulfilled: 'hardEdgeSummary',
    rejected: 'hardEdgeSummaryError'
  },
  getSoftEdgeSummary :{
    pending: 'softEdgeSummaryStatus',
    fulfilled: 'softEdgeSummary',
    rejected: 'softEdgeSummaryError'
  }
  // Add other APIs and their corresponding states here
};

export const getServiceS3Folders = createAsyncThunk('gisDashboardSlice/getServiceS3Folders', async() => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/files?parentdir=/${localStorage.getItem('organization')}/data_files`);
  return response.data.results;
});

export const getFrequencyS3Folders = createAsyncThunk('gisDashboardSlice/getFrequencyS3Folders', async(selectedService) => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/files?parentdir=/${localStorage.getItem('organization')}/data_files/${selectedService.selectedService}&type=dir`);
  return response.data.results;
});


export const getserviceLocations = createAsyncThunk('gisDashboardSlice/getserviceLocations', async(selectedService) => {
  const response = await axios.get(`/${localStorage.getItem('organization')}/locations?location_type=${selectedService.type}`);
  return response.data.results;
});

// Create a single thunk to handle multiple API calls based on the provided configuration
export const gets3DataFolderItems = createAsyncThunk(
  'gisDashboard/gets3DataFolderItems',
  async ({ apiName, apiEndpoint }) => {
    const response = await axios.get(apiEndpoint);
    console.log(apiName, apiEndpoint, response.data, 'resppppp')
    return { apiName, data: response.data.results };
  }
);



const gisDashboardSlice = createSlice({
  name: 'gisDashboard',
  initialState,
  reducers: {
    reset(state) {
      state = { ...initialState };
    }
  },
  extraReducers: builder => {
    builder
      .addCase(revertAll, () => initialState)
      .addCase(getServiceS3Folders.pending, (state, action) => {
        state.serviceS3FoldersStatus = APIStatus.loading;
        state.serviceS3Folders = null;
      })
      .addCase(getServiceS3Folders.fulfilled, (state, action) => {
        state.serviceS3Folders = action.payload;
      })
      .addCase(getServiceS3Folders.rejected, (state, action) => {
        state.serviceS3FoldersStatus = APIStatus.failed;
        state.serviceS3FoldersError = action.error;
      })
      .addCase(getFrequencyS3Folders.pending, (state, action) => {
        state.frequencyS3FoldersStatus = APIStatus.loading;
        state.frequencyS3Folders = null;
      })
      .addCase(getFrequencyS3Folders.fulfilled, (state, action) => {
        state.frequencyS3Folders = action.payload;
      })
      .addCase(getFrequencyS3Folders.rejected, (state, action) => {
        state.frequencyS3FoldersStatus = APIStatus.failed;
        state.frequencyS3FoldersError = action.error;
      })

      .addCase(getserviceLocations.pending, (state, action) => {
        state.serviceLocationsStatus = APIStatus.loading;
        state.serviceLocations = null;
      })
      .addCase(getserviceLocations.fulfilled, (state, action) => {
        state.serviceLocations = action.payload;
      })
      .addCase(getserviceLocations.rejected, (state, action) => {
        state.serviceLocationsStatus = APIStatus.failed;
        state.serviceLocationsError = action.error;
      })
      .addCase(gets3DataFolderItems.pending, (state, action) => {
        const { apiName } = action.meta.arg;
        state[apiConfig[apiName].pending] = APIStatus.loading;
        state[apiConfig[apiName].fulfilled] = null;
        state[apiConfig[apiName].rejected] = null;
      })
      .addCase(gets3DataFolderItems.fulfilled, (state, action) => {
        const { apiName, data } = action.payload;
        state[apiConfig[apiName].fulfilled] = data;
        state[apiConfig[apiName].pending] = APIStatus.loaded;
      })
      .addCase(gets3DataFolderItems.rejected, (state, action) => {
        const { apiName } = action.meta.arg;
        console.log(apiName, apiConfig[apiName])
        state[apiConfig[apiName].pending] = APIStatus.failed;
        state[apiConfig[apiName].rejected] = action.error;
      })    
  }
});

export const { reset } = gisDashboardSlice.actions;

export default gisDashboardSlice.reducer;