import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { APIStatus } from '../APIStatus';

const initialState = {
  isLoading: 'loading',
  resetPasswordStatus: null,
  resetPasswordError: null,
  resetPasswordEmailStatus: null,
  resetPasswordEmailError: null,
  changePassword: null,
  changePasswordError: null,
  verifyEmail: null,
  verifyEmailError: null,
};

export const verifyEmailId = createAsyncThunk(
  'verifyEmailId',
  async(payload) => {
    const data = { key: payload };
    const response = await axios.post('/verify-email', data,);
    return response;

  }
);

export const resetUserPassword = createAsyncThunk(
  'resetUserPassword',
  async(payload) => {
    const response = await axios
      .post('/reset-password', payload)
      .then((res) => {
        return res;
      });
    return response;
  }
);

export const resetPasswordEmail = createAsyncThunk(
  'resetPasswordEmail',
  async(payload) => {
    const response = await axios.post('/reset-password-confirm', payload);

    return response;
  }
);

export const changeUserPassword = createAsyncThunk('changeUserPassword',
  async(payload) => {
    const response = await axios.post(payload.url, payload.data);
    return response;
  });

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    reset(state, action) {
      state.resetPasswordStatus = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmailId.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
        state.verifyEmail = null;
        state.verifyEmailError = null;
      })
      .addCase(verifyEmailId.fulfilled, (state, action) => {
        state.isLoading = APIStatus.loaded;
        state.verifyEmail = action.payload;
        state.verifyEmailError = null;
      })
      .addCase(verifyEmailId.rejected, (state, action) => {
        state.isLoading = APIStatus.failed;
        state.verifyEmail = null;
        state.verifyEmailError = action.error; 
      }).addCase(resetUserPassword.pending, (state, action) => {
        state.resetPasswordError = null;
        state.resetPasswordStatus = null;
        state.isLoading = APIStatus.loading;
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.resetPasswordStatus = action.payload.data.detail;
        state.resetPasswordError = null;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.resetPasswordError = action.error.message;
        state.isLoading = APIStatus.failed;
        state.resetPasswordStatus = null;
      })
      .addCase(resetPasswordEmail.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
      })
      .addCase(resetPasswordEmail.fulfilled, (state, action) => {

        state.resetPasswordEmailStatus = action.payload.data.detail;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(resetPasswordEmail.rejected, (state, action) => {
        state.resetPasswordEmailError = action.error.message;
        state.isLoading = APIStatus.failed;
      })
      .addCase(changeUserPassword.pending, (state, action) => {
        state.isLoading = APIStatus.loading;
        state.changePassword = null;
        state.changePasswordError = null;
      })
      .addCase(changeUserPassword.fulfilled, (state, action) => {
        state.changePassword = action.payload;
        state.changePasswordError = null;
        state.isLoading = APIStatus.loaded;
      })
      .addCase(changeUserPassword.rejected, (state, action) => {
        state.changePasswordError = action.error;
        state.changePassword = null;
        state.isLoading = APIStatus.failed;
      });

  },
});

export const { reset } = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
