import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { useLayoutState } from '../../context/LayoutContext';
import { RedirectPageProvider } from '../../context/RedirectPageContext';
import Header from '../Header';
import Sidebar from '../Sidebar';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';


import useStyles from './styles';

const stripePromise = loadStripe('pk_test_51P8zvbP5tPldQZ3yf9N0zcBbixxYZJ918dkpLnUbAHXHJ7TKDLdrPhAnHvLhyjs2c4KVFnTx8tp85RIyzaAfz0ed00zsaaSn33'); // Replace with your Stripe publishable key

const RedirectPage = React.lazy(() => import('../../pages/redirectPage/RedirectPage'));

const GISDashboard = React.lazy(() => import('../../pages/gisDashboard/GisDashboard'));
const NewUser = React.lazy(() => import('../../pages/newUser/NewUser'));
const SubscriptionPlans = React.lazy(() => import('../../pages/subscriptionPlans/SubscriptionPlans'));
const PaymentSuccess = React.lazy(() => import('../../pages/subscriptionPlans/PaymentSuccess'));
const TopupWallet = React.lazy(() => import('../../pages/topupWallet/TopupWallet'));


var Layout = (props) => {
  var classes = useStyles();
  var layoutState = useLayoutState();
  // usePageTracking();

  useEffect(() => {
    if (layoutState.dashboardList) {
      // console.log('layoutState.dashboardList', layoutState.dashboardList)
    }
  }, [layoutState.dashboardList]);

  return (
    <div className={classes.root}>
      <>
        {!window.ReactNativeWebView && <>
          <Header history={props.history} />
          {layoutState.isSidebarOpened && <Sidebar />}
        </>}
        <div
          className={classnames(classes.content, {
            // [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          {!window.ReactNativeWebView && <div className={classes.fakeToolbar} />}
          <Suspense fallback={<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5%' }}> <CircularProgress size={24} /> </div>}>
            <Switch>
              <Route path="/app/GISDashboard">
                <GISDashboard />
              </Route>
              <Route path="/app/newUser">
                <NewUser />
              </Route>
              <Route path="/app/subscriptionPlans">
                  <SubscriptionPlans />
              </Route>
              <Route path="/app/paymentSuccess">
                  <PaymentSuccess/>
              </Route>
              <Elements stripe={stripePromise}>
                <Route path="/app/topupWallet">
                    <TopupWallet/>
                </Route>
              </Elements>
              <Route path="/app/redirectPage">
                <RedirectPageProvider>
                  <RedirectPage />
                </RedirectPageProvider>
              </Route>
            </Switch>
          </Suspense>
        </div>
      </>
    </div>
  );
};

export default withRouter(Layout);
