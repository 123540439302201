import { configureStore } from '@reduxjs/toolkit';
import organizationsSlice from './slices/organizationsSlice';
import resetPasswordSlice from './slices/resetPasswordSlice';
import usersSlice from './slices/usersSlice';
import gisDashboardSlice from './slices/gisDashboardSlice';

export const Store = configureStore({
  reducer: {
    organization: organizationsSlice,
    users: usersSlice,
    resetPasswordSlice,
    gisDashboard : gisDashboardSlice
  }
});

// The thunk middleware was automatically added